import React from "react"

const InStock = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g
        id="Group_6892"
        data-name="Group 6892"
        transform="translate(-1144 -341)"
      >
        <circle
          id="Ellipse_36"
          data-name="Ellipse 36"
          cx="8"
          cy="8"
          r="8"
          transform="translate(1144 341)"
          fill="#92d400"
        />
        <path
          id="Path_11359"
          data-name="Path 11359"
          d="m1148.972 347.605 3.026 3.026 3.032-3.032"
          fill="none"
          stroke="#fff"
          stroke-width="1.5"
        />
      </g>
    </svg>
  )
}

export default InStock
