import React from "react"
import style from "./pill.mod.scss"
import cn from "classnames"

const Pill = ({ text, type, icon, disabled = false, isCellar, color }) => {
  return (
    <div
      className={cn(style.pill, {
        [`${style.pill}--type-${type}`]: type,
        [`${style.pill}--color-${color?.toLowerCase()}`]: color,
        [`${style.pill}--disabled`]: disabled,
        [`${style.pill}--cellar`]: isCellar,
      })}
    >
      {icon && (
        <div className={style.pill__svg}>
          <svg width="30" height="30">
            <image xlinkHref={icon} width="30" />
          </svg>
        </div>
      )}
      {color && <span className={style.pill__color}></span>}
      <p>{text}</p>
    </div>
  )
}

export default Pill
