import React, { useState } from "react"
import Container from "../container/container"
import style from "./product-intro.mod.scss"
import cn from "classnames"
import demoImage from "../../assets/images/demoProduct.png"
import InStock from "../icons/inStock"
import QuantitySelector from "../button/quantity-selector"
import CartButton from "./cart-button"

const ProductIntro = ({
  id,
  name,
  description,
  price,
  discountedPrice,
  rrp,
  shippingMessage,
  bulkDiscountOffer,
  image,
  minOrder,
  grape,
  unit,
  year,
  wineryID,
  winery,
  region,
  tags,
  type,
  inventory,
  isFetchingInventory,
  isPrivateCellar,
  stickerText,
}) => {
  const handleImgError = (e) => {
    e.target.src = demoImage
  }

  const [quantity, setQuantity] = useState(1)

  const [gtmEventData, setGtmEventData] = useState({
    id: id,
    name: name,
    price: price,
    brand: winery,
    category: type,
  })

  const tagsList = [{ name: region }, { name: type }, ...tags]

  const isLowStock = inventory <= 3

  const isOnSale = Boolean(rrp && discountedPrice && discountedPrice > 0)

  return (
    <Container gutters width={"large"} bg={"light"}>
      <div className={style.intro}>
        <Container borderRadius bg={"white"}>
          <div className={style.intro__wrap}>
            <div className={style.intro__image}>
              <img src={image} alt="" onError={handleImgError} />
              {/* <img src={demoImage} alt="" /> */}
              {isPrivateCellar && stickerText && (
                <div className={style.intro__sticker}>
                  <span>{stickerText}</span>
                </div>
              )}
            </div>
            <div className={style.intro__content}>
              
              {!isFetchingInventory && <div className={cn(style.intro__stock, {[style.intro__lowstock]: isLowStock, [style.intro__nostock]: inventory < 1})}>
                <span>
                  {isLowStock ? `${inventory} bottles remaining` : "In stock"}
                </span>{" "}
                <InStock />
              </div>}
              {isFetchingInventory && <div className={style.intro__stock}>checking stock ...</div>}

              <div className={style.intro__heading}>
                <h1>{name}</h1>
              </div>

              <div className={style.intro__tags}>
                {tagsList.map((item, i) => (
                  <span key={`tag${i}`}>{item.name}</span>
                ))}
              </div>

              <div className={style.intro__price}>
                <p>
                  {!isOnSale && <span>{`$ ${price.toFixed(2)} AUD`}</span>}
                  {isOnSale && (
                    <>
                      <span className={style.intro__oldprice}>
                        {`$ ${rrp.toFixed(2)}`}
                      </span>
                      <span className={style.intro__saleprice}>
                        {`$ ${discountedPrice.toFixed(2)} AUD`}
                      </span>
                    </>
                  )}
                </p>
              </div>

              <div className={style.intro__buttons}>
                <div className={style.intro__qty}>
                  <QuantitySelector
                    quantity={quantity}
                    setQuantity={(qty) => setQuantity(qty)}
                  />
                </div>
                <div className={style.intro__cart}>
                  <CartButton
                    id={id}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    wineryID={wineryID}
                    fullWidth
                    primary
                    label={"Add to Cart +"}
                    gtmEventData={gtmEventData}
                  />
                </div>
              </div>

              {(minOrder > 1 || (bulkDiscountOffer && bulkDiscountOffer.length > 0)) && <div className={style.intro__order}>
                {bulkDiscountOffer && bulkDiscountOffer.length > 0 && bulkDiscountOffer.map((offer, i) => {
                  return (
                    <div className={style.intro__offer} key={`offer${i}`}>${offer.price} a bottle when you buy {offer.quantity} or more</div>
                  )
                })}
                {(minOrder > 1) && <div className={style.intro__min}>
                  <p>
                    Buy any <span>{minOrder}</span> bottles from {winery} to
                    meet their minimum order requirement.
                  </p>
                </div>}
              </div>}

              {shippingMessage && (
                <div
                  className={style.intro__shipping}
                  dangerouslySetInnerHTML={{
                    __html: shippingMessage
                      .replace(/free shipping/i, (x) => {
                        return `<i>${x}</i>`
                      })
                      .replace(winery, `<b>${winery}</b>`),
                  }}
                />
              )}

              <div className={style.intro__description}>
                <p>{description}</p>
              </div>

              <div className={style.intro__lower}>
                <div className={style.intro__details}>
                  <div className={style.intro__item}>
                    <p>
                      {"Region - "}
                      <span>{region}</span>
                    </p>
                  </div>
                  <div className={style.intro__item}>
                    <p>
                      {"Type - "}
                      <span>{type}</span>
                    </p>
                  </div>
                  <div className={style.intro__item}>
                    <p>
                      {"Year - "}
                      <span>{year}</span>
                    </p>
                  </div>
                  <div className={style.intro__item}>
                    <p>
                      {"Winery - "}
                      <span>{winery}</span>
                    </p>
                  </div>
                  <div className={style.intro__item}>
                    <p>
                      {"Grape - "}
                      <span>{grape}</span>
                    </p>
                  </div>

                  <div className={style.intro__item}>
                    <p>
                      {"Volume - "}
                      <span>{unit}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Container>
  )
}

export default ProductIntro
