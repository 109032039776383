import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from "../container/container"
import style from "./product-info.mod.scss"
import Pill from "../pill/pill"
import cn from "classnames"

const ProductInfo = ({
  descriptors,
  tastingNotes,
  bodyType,
  acidity,
  pairings,
  expertReview,
  expertAuthor,
}) => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsPairing {
        nodes {
          title
          icon {
            url
          }
        }
      }
    }
  `)

  const getDescriptors = (type) => {
    const array = descriptors.filter(
      (item) => item.wine_descripter_type === type
    )
    return array.map((desc) => desc.name)
  }
  const allPairings = data.allDatoCmsPairing.nodes
  const wineDescriptors = getDescriptors("Wine Descriptors")
  const fruitAromas = getDescriptors("Fruit aromas and flavours")
  const otherAromas = getDescriptors("Other Aromas and flavours")

  return (
    <div className={style.info}>
      <Container gutters width={"medium"} pt={1} pb={2}>
        <div className={style.info__grid}>
          <div className={style.info__about}>
            <h3>{"About the wine"}</h3>
            <div className={style.info__notes}>
              <h4>{"Tasting notes"}</h4>
              <p>{tastingNotes}</p>
            </div>
            {wineDescriptors && (
              <DescriptorBlock
                string={"Wine descriptors"}
                arr={wineDescriptors}
              />
            )}
            {fruitAromas && (
              <DescriptorBlock
                string={"Fruit aromas & flavour"}
                arr={fruitAromas}
              />
            )}
            {otherAromas && (
              <DescriptorBlock
                string={"Other aromas & flavour"}
                arr={otherAromas}
              />
            )}
            <div className={style.info__bars}>
              <Bar label={"Body type"} rating={bodyType} />
              <Bar label={"Acidity"} rating={acidity} />
            </div>
          </div>
          <div>
            <div className={style.info__pairings}>
              <h3>{"Food pairings"}</h3>
              <div className={style.info__pairings_list}>
                {allPairings.map((item) => {
                  const isActive = pairings.find(
                    (pair) => pair.name == item.title
                  )
                  return (
                    <Pill
                      type={"pairing"}
                      icon={item.icon?.url}
                      text={item.title}
                      disabled={isActive ? false : true}
                    />
                  )
                })}
              </div>
            </div>
            {expertReview && (
              <div className={style.info__expert}>
                <h3>{'Expert review'}</h3>
                <p className={style.info__expert_review}>{expertReview}</p>
                {expertAuthor && (
                  <p className={style.info__expert_author}>{expertAuthor}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ProductInfo

const DescriptorBlock = ({ string, arr }) => (
  <div className={style.info__descriptor}>
    <h4>{string}</h4>
    <p>{arr.join(", ")}</p>
  </div>
)

const Bar = ({ label, rating }) => {
  return (
    <div
      className={cn(style.info__bar, {
        [`${style.info__bar}--${rating.toLowerCase()}`]: rating,
      })}
    >
      <p>{label}</p>
      <div className={style.info__bar_bg}>
        <div className={style.info__bar_point}></div>
      </div>
      <p>{rating}</p>
    </div>
  )
}
