import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import BorderWrapper from "../components/border-wrapper/border-wrapper"
import ProductIntro from "../components/product/product-intro"
import ProductInfo from "../components/product/product-info"
import ContentImgText from "../components/content-img-text/content-img-text"
import Container from "../components/container/container"
import ProductSlider from "../components/product/product-slider"
import { getWineDetail } from "../services/product"
import {
  eventProductClick,
  eventProductDetailImpression,
  setWineEvent,
} from "../services/gtm-events"

const WineDetail = ({ data, location }) => {
  const {
    datoCmsGrape,
    datoCmsCellar,
    portalWine,
    relatedGrape,
    relatedCategory,
  } = data
  const {
    id,
    category,
    name,
    price,
    discounted_price,
    rrp,
    shiiping_message,
    bulk_discount_offer,
    acidity,
    body_type,
    maker_description,
    taste_characteristics,
    year,
    grape,
    unit,
    winery,
    food_pairing,
    image,
    inventory,
    wine_descriptors,
    expert_review_text,
    expert_review_author,
    wine_tags,
  } = portalWine

  const [currentInventory, setCurrentInventory] = useState(inventory)
  const [isFetchingInventory, setIsFetchingInventory] = useState(true)

  useEffect(() => {
    if (location.state && location.state.gtmEvent) {
      eventProductClick(portalWine)
    }
    eventProductDetailImpression(portalWine)
    // get current inventory
    setIsFetchingInventory(true)
    getWineDetail(id).then(wine => {
      // console.log(wine);
      if (wine && wine.data && wine.data.inventory) {
        setCurrentInventory(wine.data.inventory)
      }
      setIsFetchingInventory(false)
    })
  }, [portalWine, location.state])

  const eventData = {
    price: `$${price.toFixed(2)}-aud`,
    id: id,
    name: name,
    grape: grape[0].name,
    pairings: food_pairing.map((pairing) => pairing.name).join("|"),
    region: winery.region_name,
    type: category,
    winery: winery.name,
  }
  Object.keys(eventData).map((dataItem) => {
    eventData[dataItem] = eventData[dataItem].toLowerCase().replaceAll(" ", "-")
  },{})

  useEffect(() => {
    setWineEvent(eventData)
  }, [])

  const relatedWines = [...relatedGrape.nodes, ...relatedCategory.nodes]
  const filteredRelated = relatedWines.filter((wine) => wine.id !== id)
  const removedDuplicatesRelated = Array.from(
    new Set(filteredRelated.map((a) => a.id))
  ).map((id) => {
    return filteredRelated.find((a) => a.id === id)
  })

  const privateCellarTag = wine_tags.find(t => t.name === "Private Cellar")

  return (
    <Layout
      seoTitle={`iHeartWine | Shop Wine Online | ${name}`}
      seoDescription={maker_description}
    >
      <BorderWrapper topColor={"light"} topOnly>
        <ProductIntro
          id={id}
          name={name}
          price={price}
          discountedPrice={discounted_price}
          rrp={rrp}
          shippingMessage={shiiping_message}
          bulkDiscountOffer={bulk_discount_offer}
          image={image}
          minOrder={winery.minimum_wines_per_order}
          grape={grape[0].name}
          unit={unit[0].name}
          year={year}
          wineryID={winery.id}
          winery={winery.name}
          region={winery.region_name}
          description={maker_description}
          tags={wine_tags}
          type={category}
          inventory={currentInventory}
          isFetchingInventory={isFetchingInventory}
          isPrivateCellar={typeof privateCellarTag !== "undefined"}
          stickerText={datoCmsCellar.stickerText}
        />
      </BorderWrapper>
      <ProductInfo
        descriptors={wine_descriptors}
        tastingNotes={taste_characteristics}
        bodyType={body_type}
        acidity={acidity}
        pairings={food_pairing}
        expertReview={expert_review_text}
        expertAuthor={expert_review_author}
      />
      {datoCmsGrape?.image && (
        <Container gutters width={"medium"} pt={2} pb={2}>
          <ContentImgText
            subheading={"The Grape"}
            heading={datoCmsGrape.title}
            text={datoCmsGrape.description}
            image={datoCmsGrape.image}
            imageSide={"right"}
          />
        </Container>
      )}
      {/* <Container gutters width={"medium"} pt={2} pb={2}>
        <WineryFeatured 
          title={winery.name}
          subHeading={'Winery'}
          // locationOverride={}
          content={winery.description}
          portalId={winery.id}
          buttonText={'Find out more'}
          portalImage={winery.banner}
        />
      </Container> */}
      <Container pt={2} pb={1} bg={"light"} width={"none"}>
        <ProductSlider
          heading={"Similar Wines"}
          subHeading={"You also might like"}
          items={removedDuplicatesRelated.sort((a, b) => 0.5 - Math.random())}
          maxSlides={20}
        />
      </Container>
    </Layout>
  )
}

export default WineDetail

export const query = graphql`
  query WineDetailQuery($id: String!, $grapeId: Int!, $categoryId: String!) {
    datoCmsGrape(portalId: { eq: $grapeId }) {
      id: originalId
      description
      title
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "480", fit: "crop", w: "720", q: 60 }
        )
        alt
      }
    }
    datoCmsCellar {
      stickerText
    }
    relatedCategory: allPortalWine(
      filter: { category: { eq: $categoryId } }
      limit: 20
    ) {
      nodes {
        id
        name
        price
        rrp
        discounted_price
        image
        winery {
          id
          minimum_wines_per_order
        }
      }
    }
    relatedGrape: allPortalWine(
      filter: { grape: { elemMatch: { id: { eq: $grapeId } } } }
      limit: 20
    ) {
      nodes {
        id
        name
        price
        rrp
        discounted_price
        image
        winery {
          id
          minimum_wines_per_order
        }
      }
    }
    portalWine(id: { eq: $id }) {
      name
      price
      rrp
      discounted_price
      acidity
      body_type
      category
      image
      inventory
      expert_review_text
      expert_review_author
      maker_description
      taste_characteristics
      minimum_order_qty
      shiiping_message
      bulk_discount_offer {
        quantity
        price
        status
      }
      year
      wine_type
      wine_tags {
        id
        name
      }
      food_pairing {
        id
        name
      }
      grape {
        name
        id
      }
      unit {
        name
      }
      winery {
        address
        country
        id
        name
        description
        minimum_wines_per_order
        banner
        postcode
        region_id
        region_name
        region {
          id
          name
        }
      }
      wine_descriptors {
        name
        wine_descripter_type
      }
      id
    }
  }
`
